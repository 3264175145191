import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'gatsby';

const CTA = () => {
  return (
    <div className='CTA'>
      <Container>
        <h2>
          <span>Enlightened</span> sessions
        </h2>
        <p>Join in this unique experience</p>
        <Link to='/get-involved/' className='button'>
          Get involved
        </Link>
      </Container>
    </div>
  );
};

export default CTA;
