import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const TopicsCard = ({ topic, description, url, background }) => {
  return (
    <div
      className='topicsCard'
      // style={{ backgroundImage: `url(${background})` }}
    >
      <h2>{topic}</h2>
      <p>{description}</p>
      <Link to={url} className='button'>
        LEARN MORE
      </Link>
    </div>
  );
};

TopicsCard.propTypes = {
  topic: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  background: PropTypes.string,
};

export default TopicsCard;
