import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';

import bgWorld from 'assets/images/changing_world_bg.jpg';
import bgAct from 'assets/images/act_now_bg.jpg';
import bgPart from 'assets/images/part_movement_bg.jpg';
import VideoSlide from '../components/VideoSlide';
import { Col, Row, Container } from 'react-bootstrap';
import Topics from '../components/Topics';
import CTA from '../components/CTA';

import black from 'assets/images/black_visual.jpg';
import bg from 'assets/images/topics_bg.png';

const IndexPage = () => {
  return (
    <Layout pageName='home'>
      <Helmet>
        <title>EY - The enlightened session</title>
      </Helmet>
      <VideoSlide />
      <div
        className='intro'
        style={{
          backgroundImage: `url(${black}), radial-gradient(circle at 50% 0, #262323, #0c0c0e 52%)`,
        }}
      >
        <div className='about' id='about'>
          <Container>
            <h1>
              <span>Enlightened</span> sessions
            </h1>
            <h3>
              Book your free <span>enlightened session.</span> And be inspired.
            </h3>
            <p>
              Welcome to the <strong>new age of enlightened.</strong> Discover
              how you can redefine energy as we know it today; explore how
              digital tools will open up opportunities never imagined before and
              find out why breaking with convention will determine your
              company’s survival in tomorrow’s world.
            </p>
            <p>
              By joining an EY enlightened session, hosted by our guest
              freethinkers, you will be inspired by what this new energy era has
              in store for you. Open your mind and prepare to accelerate.
            </p>
          </Container>
        </div>
        <div className='quote'>
          <h2>
            “Today’s freethinking is <span>tomorrow’s common sense</span>”
          </h2>
        </div>
      </div>
      <div className='world' style={{ backgroundImage: `url(${bgWorld})` }}>
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <h2>
                The energy world <span>is changing</span>
              </h2>
              <p>
                The energy industry is on a transformational journey. There is
                no return. On top of the usual energy industry performance
                measures, utilities will now be judged for their flexibility and
                agility and their capacity to innovate and embrace digital.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='act' style={{ backgroundImage: `url(${bgAct})` }}>
        <Container>
          <Row>
            <Col>
              <h2>
                We need to <span>act quickly.</span> And we need to{' '}
                <span>act now.</span>
              </h2>
              <p>
                Digital technologies will enable a revolutionary, science-led,
                sustainable energy future that breaks with century-old practices
                and engages you in previously unimagined energy possibilities.
                Be ready to go beyond tradition, open your mind to new ideas and
                see where it takes you.
              </p>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div className='part' style={{ backgroundImage: `url(${bgPart})` }}>
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <h2>
                Be part of <span>the movement</span>
              </h2>
              <p>
                EY is giving you the opportunity to be inspired by freethinkers
                who see our energy future very differently. In a free
                enlightened session, tailored for you, we will help you and your
                teams to envisage your huge potential.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Topics />
      <div
        className='CTAHome'
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <CTA />
      </div>
    </Layout>
  );
};

export default IndexPage;
