import React from 'react';
import TopicsCard from './TopicCard';

import digitalWorkforce from '../assets/images/topics/Workforce.jpg';
import customer from '../assets/images/topics/customer.jpg';
import innovation from '../assets/images/topics/InnovationDarker.jpg';
import assets from '../assets/images/topics/Workforce.jpg';

import { Container } from 'react-bootstrap';

import bgIntro from '../assets/images/topics_bg.png';
import bg from '../assets/images/Speaker_visual.jpg';

const Topics = () => {
  return (
    <>
      <div
        className='topicsIntro'
        style={{ backgroundImage: `url(${bgIntro})` }}
        id='topics'
      >
        <Container>
          <div className='expectWrapper'>
            <div className='expectItem'>
              <h6>4 topics brought to you by top keynote speakers</h6>
              <p>
                Choose from 4 different topics that are crucial for your company
                to thrive in the new energy age
              </p>
            </div>
            <div className='expectItem'>
              <h6>
                To inspire and <br />
                exchange thoughts
              </h6>
              <p>
                Be immersed in the ideas of freethinkers, open up your mind to
                new possibilities and join us in the new energy conversation
              </p>
            </div>
            <div className='expectItem'>
              <h6>
                Exclusively for <br /> your company
              </h6>
              <p>
                A session organized exclusively for you to learn about the new
                energy era in the context of the challenges you experience today
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className='topics' style={{ backgroundImage: `url(${bg})` }}>
        <h3>
          Discover <span>the topics and speakers</span>
        </h3>
        <div className='topicsContainer'>
          <TopicsCard
            topic='Workforce'
            speaker='John Johanson'
            title='Innovation manager'
            company='Google'
            url='/topic/workforce'
            description='Thriving in this new age only succeeds if organizations put humans at the center of their transformation. Be inspired on how to engage your employees with a different mindset and equip them with the technical skillset to ensure your business is prepared for tomorrow.  

          '
            background={digitalWorkforce}
          />
          <TopicsCard
            topic='Customer'
            speaker='John Johanson'
            title='Innovation manager'
            company='Google'
            url='/topic/customer'
            description='Customers’ expectations change at the speed of light. Join us in an engaging conversation on the energy customer of tomorrow and immerse yourself in how to win customers in tomorrow’s competitive landscape. '
            background={customer}
          />
          <TopicsCard
            topic='Innovation'
            speaker='John Johanson'
            title='Innovation manager'
            company='Google'
            url='/topic/innovation'
            description='Today we’re on the edge of a totally new wave of innovation. Opportunities created by the latest technologies and renewed ways of looking at the world enable us to reimagine the tomorrow. Discover where to innovate, how to innovate, and how to do it at scale together. '
            background={innovation}
          />
          <TopicsCard
            topic='Assets'
            speaker='John Johanson'
            title='Innovation manager'
            company='Google'
            url='/topic/assets'
            description='The energy industry remains an asset business, and our most significant asset, the energy network, is changing shape. It has become more distributed, more intermittent, more complex to manage. Join us to determine how to cope with these challenges and where to invest going forward.'
            background={assets}
          />
        </div>
      </div>
    </>
  );
};

export default Topics;
