import React from 'react';
import ReactPlayer from 'react-player';

import placeholder from 'assets/images/Enlightened_bg.jpg';
// import playButton from 'assets/images/noun-play-button-2978980@3x.png';
import pauseButton from 'assets/images/Pausesvg.svg';

const VideoSlide = () => {
  const [isPlaying, setPlay] = React.useState(false);

  return (
    <div className='video' style={{ backgroundImage: `url(${placeholder})` }}>
      {!isPlaying ? (
        <div className='play'>
          {/* <button onClick={() => setPlay(!isPlaying)}>
            <img src={playButton} alt='Play Button' />
          </button> */}
        </div>
      ) : (
        <div className='playerWrapper'>
          <ReactPlayer
            className='react-player'
            url='https://www.youtube.com/watch?v=BIhNsAtPbPI'
            width='100%'
            height='100%'
            playing={isPlaying}
            loop={true}
          />
          <div className='pause'>
            <button onClick={() => setPlay(!isPlaying)}>
              <img src={pauseButton} alt='Pause Button' />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoSlide;
